import React, { useState,useEffect } from "react";
import {
  Container,
  Input,FormGroup
} from "reactstrap";
import { useLoggedIn } from '../../Components/Hooks/useLoggedIn';
import { authProvider } from '../../Components/Hooks/authProvider';
import CarouselCards from './CarouselCards';

const Purchases = () => {
  document.title = "Compras | Panda Móvil";


  const { user } = useLoggedIn();
  const [currentMsisdn, setCurrentMsisdn] = useState(user?.msisdn );
  const { phones } = authProvider();
  useEffect(() => {
    if (user) {
      setCurrentMsisdn(user?.msisdn);
    }
  }, [user]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h1>Recargas</h1>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-6">Número a recargar:</span>
            <div style={{width: '20rem'}}>
            <FormGroup>
          <Input type="select" onChange={(e) => setCurrentMsisdn(e.target.value)}>
          { user.msisdn && <option value={user.msisdn}>Tú {user.name+' '+user.last_name+' ('+user.msisdn+')'}</option> }

{ phones && phones.map(phone =>
  phone.msisdn && <option key={phone.msisdn} value={phone.msisdn}>{phone.name+' '+phone.last_name+' ('+phone.msisdn+')'}</option>
) }
          </Input>
        </FormGroup>
            </div>
          </div>
         <CarouselCards currentMsisdn={currentMsisdn}/>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Purchases;
