import React from "react";
import {
  Button,
  Card,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import Paypal from "../../assets/images/payments/paypal.png";
import MercadoPago from "../../assets/images/payments/MercadoPago.svg";
import { useLoggedIn } from "../../Components/Hooks/useLoggedIn";
import { authProvider } from "../../Components/Hooks/authProvider";
import { useEffect, useState } from "react";
import SpinnerCool from "../../Components/Common/Spinner/Spinner";
import StripePayment from './Stripe/StripePayment';
import StripeSuscription from './Stripe/StripeSuscription';
import { usePayments } from './usePayments';
import socialNetworkImage from "@/assets/images/socialNetwork.png";
import checkRed from "@/assets/images/checkRed.png";

const ModalPayments = ({ isOpen, onToggle, e, currentMsisdn, isSuscription=false}) => {
  const { user } = useLoggedIn();
  const { phones } = authProvider();

  const { usePayMercadoPago, usePaypal } = usePayments();
  const { mutate: payMercadoPago, isPending: loadingMP, isSuccess: successMP } = usePayMercadoPago;
  const { mutate: payPaypal, isPending: loadingPaypal, isSuccess: successPaypal } = usePaypal;

  const [isChecked, setIsChecked] = useState(false);


  const [showStripeFields, setShowStripeFields] = useState(false);
  const [stripePaymentProcess, setStripePaymentProcess] = useState(false);


  const handleMercadoPagoPay = () => {
    payMercadoPago({ productId: e.id, msisdn: currentMsisdn });
  };

  const handlePaypalPay = () => {
    payPaypal({ productId: e.id, msisdn: currentMsisdn });
  };

  const [planDetails, setPlanDetails] = useState(null);

  useEffect(() => {
    const parsedData = parseHtmlData(e.description);
    setPlanDetails(parsedData);
  }, []);


  const parseHtmlData = (html) => {
    const planDuration = html.match(/Disfruta por (\d+) (MESES|días)/i)?.[0];
    const dataAllowance = html.match(/<strong>(\d+GB)<\/strong>/)?.[1];
    return {
      planDuration,
      dataAllowance,
      benefitDetails: {
        description: html.match(/para navegar\*/)?.[0],
        socialNetworks: html.match(/Redes Sociales/)?.[0],
        unlimitedCalls: html.match(/ILIMITADAS/)?.[0],
        regions: html.match(/Nacionales, EUA Y Canad\u00e1/)?.[0],
      },
    };
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div>
      <React.Fragment>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            onToggle();
          }}
          centered
          className="mx-auto"
          style={{ maxWidth: "700px" }}
        >
          <ModalHeader className="modal-title d-flex justify-content-center">
            {/* <h5 className="text-primary">Medio de pago</h5> */}
          </ModalHeader>
          <ModalBody className="text-center p-2 mx-2">
            <Row className="justify-content-center d-flex align-items-center">
            <SpinnerCool  isLoading={stripePaymentProcess}/>
              <Col md={6}>
                <Row className="justify-content-center">
                  <Card className="text-center" style={{ width: "330px" }}>
                  <div
                    style={{
                      flex: 1,
                      background: "linear-gradient(240deg, #0a3359 0%, #131a20 50%)",
                      borderRadius: "30px",
                      padding: "16px",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>{e.name}</h2>
                      {planDetails && (
                        <div style={{ textAlign: "center", paddingTop: "8px" }}>
                          <p style={{ fontSize: "28px", fontWeight: "bold", color: "white" }}>
                            {planDetails.dataAllowance}
                          </p>
                          <p style={{ fontSize: "40px", fontWeight: "bold", color: "red" }}>
                            ${e.amount}
                          </p>
                          <p style={{ fontSize: "20px", color: "white" }}>{planDetails.planDuration}</p>
                          <p style={{ fontSize: "28px", fontWeight: "bold", color: "white" }}>
                            {planDetails.dataAllowance}
                          </p>
                          <p style={{ fontSize: "20px", color: "white" }}>Para navegar *</p>
                          <div style={{ width: "100%", height: "60px", position: "relative" }}>
                            <img
                              src={socialNetworkImage}
                              alt="social"
                              style={{ position: "absolute", right: "-10px", top: "50%", transform: "translateY(-15px)" }}
                            />
                          </div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "24px" }}>
                            <img
                              src={checkRed}
                              alt="check"
                              style={{ width: "25px", height: "25px", marginRight: "8px" }}
                            />
                            <p style={{ fontSize: "20px", fontWeight: "bold", color: "white" }}>
                              Minutos y Sms Incluidos
                            </p>
                          </div>
                          <h3 style={{ fontSize: "20px", fontWeight: "bold", color: "white", paddingTop: "24px" }}>
                            {planDetails.benefitDetails.regions}
                          </h3>
                        </div>
                      )}
                    </div>
                    </div>

                  </Card>
                </Row>
              </Col>

              <Col md={6}>
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <Label className="text-primary">Número {currentMsisdn}</Label>
                  </Col>

                  <Col className="col-12 mt-4 text-primary">
                  {isSuscription?'Suscribir'
                  :
                    'Elige pagar con:'
                  }
                  </Col>

                  {!isSuscription && !showStripeFields && <>
                    <button className="mt-4 cursor-pointer"
                      type="button"
                      disabled={loadingPaypal || successPaypal}
                      style={{
                        background: "#ffc439",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                      }}
                      onClick={handlePaypalPay}
                    >
                      { loadingPaypal ? <Spinner color="white" /> : <img src={Paypal} height="100%" /> }
                    </button>

                    <button className="mt-4 cursor-pointer"
                      type="button"
                      disabled={loadingMP || successMP}
                      style={{
                        background: "#a8e5ff",
                        width: "70%",
                        borderRadius: "4px",
                        border: "none",
                        height: "40px",
                        padding: "7px",
                      }}
                      onClick={handleMercadoPagoPay}
                    >
                      {loadingMP ? <Spinner color="primary" /> : <img src={MercadoPago} height="100%" /> }
                    </button>
                  </>}

                  {!isSuscription && (
                  <button className="mt-4 cursor-pointer"
                    type="button"
                    style={{
                      background: "#645aff",
                      width: "70%",
                      borderRadius: "4px",
                      border: "none",
                      height: "40px",
                      padding: "7px",
                      color: "white",
                    }}
                    onClick={() => setShowStripeFields(!showStripeFields)}
                  >
                  {showStripeFields
                  ?'Regresar'
                  :'Pago con tarjeta'
                  }
                  </button>



                  )}
                  {
                    !isSuscription  && (
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="saveCard"
                      name="saveCard"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                       className="mx-2"
                    />
                    <label htmlFor="saveCard">
                      Guardar tarjeta pago recurrente
                    </label>
                  </div>
                    )
                  }

                  
                  {!isSuscription && showStripeFields && showStripeFields && !isChecked && (
                    <StripePayment msisdn={currentMsisdn} productId={e.id} />
                  )}
                  {showStripeFields && isChecked && (
                    <StripeSuscription msisdn={currentMsisdn} productId={e.id} />
                  )} 
                  {isSuscription && <StripeSuscription msisdn={currentMsisdn} productId={e.id} /> }

                </Row>
              </Col>
              <Col xs={6} sm={5}>
                <Button
                disabled={stripePaymentProcess}
                  color="primary"
                  outline
                  onClick={onToggle}
                  className="w-100 my-4"
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </div>
  );
};

export default ModalPayments;
